import React from 'react'
import SanityMuxPlayer from 'sanity-mux-player';

const Video = (props) => {
    const { assetDocument, autoplay, loop = false, muted = true, showControls = false } = props;
    return <SanityMuxPlayer
        assetDocument={assetDocument}
        autoload={true}
        autoplay={autoplay}
        loop={loop}
        muted={muted}
        showControls={showControls}
        width={"100%"}
        playsInline
    />
}

export default Video;