import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

import * as styles from "./figure.module.css";

export function Figure({ node, mode }) {
  if (!node.asset) {
    return null;
  }

  const imageData = getGatsbyImageData(node.asset, { maxWidth: 2000 }, clientConfig.sanity);

  return (
    <>
      {mode !== "fill" ?
        <figure className={styles.root}>
          <GatsbyImage image={imageData} alt={node.alt} />
          {node.caption && <figcaption className="text-sm mt-2">{node.caption}</figcaption>}
        </figure>
        :
        <figure className={styles.rootFill}>
          <GatsbyImage image={imageData} alt={node.alt} style={{ objectFit: 'cover', height: '100%' }} imgStyle={{ objectFit: 'cover' }} objectFit="cover" objectPosition="center" loading="eager" />
        </figure>
      }
    </>
  );
}
