// extracted by mini-css-extract-plugin
export const root = "project-preview-module--root--3qp-B";
export const rootFull = "project-preview-module--rootFull--3v6a7";
export const video = "project-preview-module--video--2n7Nr";
export const text = "project-preview-module--text--tCmTB";
export const textFull = "project-preview-module--textFull--JYI-1";
export const title = "project-preview-module--title--iPzYO typography-module--responsiveTitle1--2aak3";
export const leadMediaThumb = "project-preview-module--leadMediaThumb--2zQly";
export const excerpt = "project-preview-module--excerpt--sur-J";
export const videoFull = "project-preview-module--videoFull--pBeVp";
export const mainImage = "project-preview-module--mainImage--3B1to";
export const details = "project-preview-module--details--38VT3";