// extracted by mini-css-extract-plugin
export const title1 = "typography-module--title1--3kWAX";
export const title2 = "typography-module--title2--3MCr0";
export const title3 = "typography-module--title3--3u4OD";
export const large = "typography-module--large--1Oqox";
export const base = "typography-module--base--2DtHq";
export const small = "typography-module--small--2jpCI";
export const micro = "typography-module--micro--2S5pr";
export const paragraph = "typography-module--paragraph--2ZibO";
export const blockQuote = "typography-module--blockQuote--_U6K9";
export const responsiveTitle1 = "typography-module--responsiveTitle1--2aak3";
export const responsiveTitle3 = "typography-module--responsiveTitle3--3W4yn";
export const responsiveTitle4 = "typography-module--responsiveTitle4--1LVBg";
export const link = "typography-module--link--1L3_3";